/** @jsx jsx */
import { Box, jsx } from "theme-ui"

const Footer = () => (
  <Box as="footer" variant="layout.footer">
    Copyright &copy; {new Date().getFullYear()} · Gulliver Developments Inc.
  </Box>
)

export default Footer
