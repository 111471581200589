/** @jsx jsx */
import { Flex, jsx, useColorMode, Link as TLink } from "theme-ui"
import { Link } from "gatsby"
import Navigation from "./navigation"
import SocialLinks from "./social-links"

type HeaderProps = {
  meta: {
    [key: string]: string
  }
  nav: {
    title: string
    slug: string
  }[]
}

const Header = ({ meta, nav }: HeaderProps) => {
  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === `dark`
  const toggleColorMode = (e: any) => {
    setColorMode(isDark ? `light` : `dark`)
  }

  const navEmpty = nav.length === 0

  return (
    <Flex as="header" variant="layout.header">
      <Flex
        sx={{
          fontWeight: `bold`,
          fontSize: 4,
          padding: 3,
          justifyContent: `center`,
          order: 1,
          flex: [`1 0 1%`]
        }}
      >
        <TLink
          aria-label={`${meta.siteTitle}, Back to homepage`}
          as={Link}
          sx={{ color: `text`, ":hover": { color: `primary`, textDecoration: `none` } }}
          to="/"
        >
          <img width="100%" height="auto" src={`${meta.siteImage}`} alt="Gulliver Developments" />
        </TLink>
      </Flex>
      <Flex
        sx={{
          flex: 1,
          fontWeight: `bold`,
          fontSize: 4,
          flexDirection: `column`,
          order: 2,
        }}
      >
        <div
          sx={{
            a: {
              fontSize: 4,
              color: `text`,
              display: `flex`,
              alignItems: `center`,
              "&:hover": {
                color: `primary`,
              },
              "&:not(:first-of-type)": {
                ml: 2,
              },
            },
            display: `flex`,
            justifyContent: `flex-end`,
            order: 1,
          }}
        >
          <SocialLinks />
          <button
            sx={{ display: `none`, variant: `buttons.toggle`, fontWeight: `semibold` }}
            onClick={toggleColorMode}
            type="button"
            aria-label="Toggle dark mode"
          >
            {isDark ? `Light` : `Dark`}
          </button>
        </div>
        {!navEmpty && <Navigation nav={nav} />}
      </Flex>
    </Flex>
  )
}

export default Header
